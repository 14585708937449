let $ = require('jquery');

require('select2');

window.toastr = require('toastr');


export function init() {
    $(document).ready(function () {
        $('body').tooltip({
            selector: "[data-toggle=tooltip]",
            html: true
            //container: "body"
        });

        $.each($(".select2plain"), function (i, el) {
            $(el).select2();
        });

        // Collapse ibox function
        $('.collapse-link').on('click', function () {
            let ibox = $(this).closest('div.ibox');
            let button = $(this).find('i');
            let content = ibox.children('.ibox-content');
            content.slideToggle(200);
            button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
            ibox.toggleClass('').toggleClass('border-bottom');
            setTimeout(function () {
                ibox.resize();
                ibox.find('[id^=map-]').resize();
            }, 50);
        });

    });
}