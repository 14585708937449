let $ = require('jquery');

require('datatables.net');
require('datatables.net-bs');
require('datatables.net-buttons');
require('datatables.net-buttons-bs');
require('./buttons.server-side.js');

export function init() {

}