let $ = require('jquery');

export let $body = $('body');

export function getView() {
    let view = $body.data('section') || '';

    if (view && view.length) {
        return view.trim();
    }
    else {
        return '';
    }
}