/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');
let $ = require('jquery');

let common = require('./common/common');
require('./common/dataTables');
let router = require('./utils/router');

// let header = require('./common/header');
// let footer = require('./common/footer');
// let menu = require('./common/menu');
// let form = require('./common/form');
// let gdpr = require('./common/gdpr');


function init() {
    $(document).ready(function () {
        let view = router.getView();
        if (view !== '') {
            try {
                let moduleView = require('./views/' + view);
                moduleView.init();
            } catch (err) {
                // console.log('view not found');
            }
        }
    });
}


function run() {
    common.init();
    // header.init();
    // menu.init();
    // footer.init();
    // form.init();
    // gdpr.init();
    init();
}

run();